<template>
  <div class="register left-float-top ml-30">
    <div class="flex-1">
      <div style="width: 100%; background: #508caa; margin-bottom: 15px; line-height: 20px; color: #fff;  padding-left: 20px; padding-top: 5px; padding-bottom: 5px;" v-if="is_show">
        <div>目前贵单位参与的认证类型是：{{organ.levelName}}； 当前共 {{total}} 项文件需要上传 您已上传 {{total-awaitnum}} 条，待上传项：{{awaitnum}} 条</div>
        <div class="mt-15" v-if="certResult.authBatch">当前认证批次：{{certResult.authBatch ? certResult.authBatch.name:''}}。</div>
        <div class="mt-15" v-if="certResult.first">初审结果： <span :class="certResult.first.meet === '1' ? 'greenColor' : 'redColor'">{{certResult.first.meet === '1' ? '满足' : '不满足'}}，</span><span class="pl-10" > 意见:{{certResult.first.suggestion}},</span><span  class="pl-10">审核人:{{certResult.first.operator.name}},</span><span  class="pl-10">审核时间:{{certResult.first.time}}</span></div>
        <div class="mt-15" v-if="certResult.expert">专家审核结果： <span :class="certResult.expert.meet === '1' ? 'greenColor' : 'redColor'">{{certResult.expert.meet === '1' ? '满足' : '不满足'}}，</span><span class="pl-10" > 意见:{{certResult.expert.suggestion}},</span><span  class="pl-10">审核人:{{certResult.expert.operator.name}},</span><span  class="pl-10">审核时间:{{certResult.expert.time}}</span></div>

      </div>
      <el-form ref="ruleFormRef" :model="ruleForm"  class="flex-1" status-icon>
        <el-row>
          <el-col :span="14">
            <el-form-item>
              <el-checkbox-group v-model="status" @change="changeCheckbox">
                <el-checkbox label="-1">待上传</el-checkbox>
                <el-checkbox label="0">待审核</el-checkbox>
                <el-checkbox label="1">满足</el-checkbox>
                <el-checkbox label="2">基本满足</el-checkbox>
                <el-checkbox label="3">需完善</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <span style="color: red">注：打钩为显示，如无需显示，请去掉勾选后点击查看按钮。</span>
          </el-col>
          <el-col :span="10">
            <el-button type="primary" @click="lookType">查看</el-button>
            <!--            <el-button style="margin-left: 60px;" type="primary">导出审核结果</el-button>-->
            <!--            <el-button type="primary">查看历史材料</el-button>-->
          </el-col>
        </el-row>
        <div style="margin: 10px 0"><span style="color: #999999">* 颜色说明：</span> <span style="color: black">黑色：待上传或等待审核，</span> <span style="color: green">绿色：审核结果满足，</span> <span style="color: blue">蓝色：审核结果基本满足，</span> <span style="color: red">红色：审核结果需完善</span></div>
        <el-table class="mt-20 table-cols" ref="auditTable" :row-class-name="tableRowClassName" :data="materialList" row-key="id" :expand-row-keys="expandKeys" border size="large" :show-header="false">
          <el-table-column prop="title" label="标题" min-width="800">
            <template #default="scope">
              <div v-if="scope.row.leaf" style="float: left; width: 90%; padding-left: 45px;">
                <div  class="b-color-org" type="text" size="large">
                  <div style="text-align: left;">
                    <span v-if="scope.row.result === '0' ||scope.row.result === '1' || scope.row.result === '2' || scope.row.result === '3'" style="color: #999999">{{ scope.row.title }}</span>
                    <span v-else class="t-000">{{ scope.row.title }}</span>
                  </div>
                </div>
                <div v-html="scope.row.content"></div>
              </div>
              <span v-else><span :class="scope.row.top ? 'f16':''">{{ scope.row.title }}</span> <span class="t-999 f12">（共  {{computedLength(scope.row)}} 条，待上传 <strong :class="computedNoLength(scope.row) ? 't-org' : 't-999'">{{ computedNoLength(scope.row) }}</strong> 条）</span></span>

              <!--              <span v-else><span :class="scope.row.top ? 'f16':''">{{ scope.row.title }}</span> <span class="t-999 f12">（共  {{computedLength(scope.row)}} 条，已审核 {{ computedEdLength(scope.row) }} 条，未审核 <strong :class="computedNoLength(scope.row) ? 't-org' : 't-999'">{{ computedNoLength(scope.row) }}</strong> 条）</span></span>-->
            </template>
          </el-table-column>
          <el-table-column fixed="right" prop="button" label="按钮" width="240" align="center">
            <template #default="scope">
              <el-button type="text" icon="el-icon-thumb" v-if="scope.row.leaf "  @click="handleUpload(scope.row)">
                <span v-if="'-1' == scope.row.result" class="t-cu f14" style="color: black">待上传</span>
                <span v-if="'0' == scope.row.result" class="t-cu f14" style="color: black">
                  <span v-if="null != scope.row.certCode && undefined != scope.row.certCode &&  scope.row.certCode != ''">查看</span>
                  <span v-else>待审核</span>
                </span>
                <span v-if="'1' == scope.row.result" class="t-cu f14" style="color: green">满足</span>
                <span v-if="'2' == scope.row.result" class="t-cu f14" style="color: blue">基本满足</span>
                <span v-if="'3' == scope.row.result" class="t-cu f14" style="color: red">需完善</span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="center-float-center mt-20">
          <el-button  type="primary" @click="submitForm()" size="large" :loading="loading"  >提交认证申请</el-button>
        </div>
      </el-form>
    </div>
    <!-- 上传 -->
    <el-dialog title="上传认证材料（可上传图片、PDF格式，大小不超过5M）" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">

      <div v-if="null != certKey && undefined != certKey &&  certKey != ''">
        <div style="padding: 10px 15px 10px 15px;color: #005bac;font-size: 16px !important;">
          通知：持续改进部分无需上传文件，会直接链接到数据填报平台/云平台，审核人员点击后直接查看！请确保贵院在数据填报平台/云平台所填报数据量符合要求！
        </div>
        <el-table class="mt-20" ref="auditTable"  :data="certTable" row-key="id" :expand-row-keys="expandKeys" border size="large" :show-header="false">
          <el-table-column prop="cert" label="标题" min-width="240">
            <template #default="scope">
              <div style="text-align: center;"><el-button  type="primary" @click="openCert">数据填报平台(总平台)</el-button></div>
            </template>
          </el-table-column>
          <el-table-column  prop="" label="按钮" width="800" align="center">
            <template #default="scope">
            </template>
          </el-table-column>
          <template #append  v-if="null != first.result && first.result !=undefined && first.result !='' && first.result !='0'">
            <div style="width: 100%; border-top: 1px solid #EBEEF5;">
              <div style="color: #fe733d;padding: 10px 15px;">{{first.time}}<span class="p-15">胸痛联盟办公室评审结论：{{getConclusion(first.result)}}</span><span class="p-15">意见：{{first.suggestion}}</span></div>
              <div style="color: #fe733d;padding: 10px 15px;" v-if="null != expert.result && expert.result !=undefined && expert.result !='' && expert.result !='0'">{{expert.time}}<span class="p-15">专家评审结论:{{getConclusion(expert.result)}}</span><span class="p-15">意见：{{expert.suggestion}}</span></div>

            </div>
          </template>
        </el-table>
      </div>
      <div v-else>
        <div v-for="(item,index) in materials">
          <div class="text-title">{{index+1}}、{{item.name }}</div>
          <div class="center-float-left pt-20 pb-20">
            执行时间：
            <el-date-picker
                v-model="item.execute"
                type="date"
                label="执行时间"
                placeholder="执行时间"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 200px"
                @change="changeDate"
            />
            <el-upload
                ref="upload"
                class="mr-20 ml-20"
                :action="basicUrl+'/api/dmz/ATTESTATION/file/upload'"
                :limit="1"
                :file-list="fileList"
                :show-file-list="false"
                :on-success="(response, file, filelist)=>handleExceedDialog(response, file, filelist,item.oid,item.name,item.execute,index)"
                :before-upload="beforeAvatarUpload"
            >
              <template #trigger>
                <div class="center-float-left"><el-input class="mr-10" v-model="item.fileName"  disabled>
                </el-input><el-button type="primary">选择文件</el-button></div>
              </template>
            </el-upload>
            <el-button type="primary" @click="submitUploadDialog(index,item.execute)">上传</el-button>
          </div>


        </div>
        <el-table :data="materialsDate" row-key="id" border empty-text="暂无文件.">
          <el-table-column prop="title" label="文件名称" min-width="200" />
          <el-table-column prop="name" label="材料" width="200" />
          <el-table-column prop="execute" label="执行时间" width="200" />
          <el-table-column prop="operation"  label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="danger" @click="checkMaterial(scope.row.file.url)">查看</el-button>
              <el-button type="danger" @click="handleDel(scope.row.oid,scope.row.fileId)">删除</el-button>
            </template>
          </el-table-column>
          <template #append v-if="null != first.result && first.result !=undefined && first.result !='' && first.result !='0'">
            <div style="width: 100%; border-top: 1px solid #EBEEF5;">
              <div style="color: #fe733d;padding: 10px 15px;">{{first.time}}<span class="p-15">胸痛联盟办公室评审结论：{{getConclusion(first.result)}}</span><span class="p-15">意见：{{first.suggestion}}</span></div>
              <div style="color: #fe733d;padding: 10px 15px;" v-if="null != expert.result && expert.result !=undefined && expert.result !='' && expert.result !='0'">{{expert.time}}<span class="p-15">专家评审结论:{{getConclusion(expert.result)}}</span><span class="p-15">意见：{{expert.suggestion}}</span></div>

            </div>
          </template>
        </el-table>
      </div>



    </el-dialog>
  </div>
</template>
<script>
import {getOrganDetail} from "@/api";
import certData from "@/util/certData";
import moment from "moment";
export default {
  name: "ConstructionMaterial",
  data(){
    return{
      basicUrl : this.$basicUrl,
      skipPageUrl:this.$skipPageUrl,
      loading:false,
      ruleForm: {},
      status:['-1','0','1','2','3'],
      fistMeasure:'',
      measure:[],
      currStarMonth:'',
      currEndMonth:'',
      currTime:'',
      startDate:'',
      endDate:'',
      dialogVisible: false,
      currCode:'',
      collapse:'',
      file: {name:''},
      materials:[],
      materialsDate:[],
      typeName110:'',
      organ:{},
      data:certData.data,
      basicData:certData.basicData,
      materialList:[],
      expandKeys: [],
      leafOid:'',
      leafTitle:'',
      itemId:'',
      row:{},
      certKey:'',
      level:'',
      certTable:[{code:'1'}],
      authProfileId:'',
      total:'',
      awaitnum:'',
      is_show:true,
      fileList:[],
      first:{},//初审
      expert:{},//专家审核
      conclusion:'',
      certResult:{},


    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('cpis-web_userInfo'))) {
      this.organId = JSON.parse(localStorage.getItem('cpis-web_userInfo')).id
    }
    this.reqOrganDetail();
    this.getTime();

  },
  methods:{
    moment,
    tableRowClassName({row, rowIndex}) {
      if (row.top) {
        return 'bg-blue-o';
      }
      if (row.result === '-1' && row.leaf) {
        return 'bg-org-o';
      }
      return '';
    },
    // 计算材料条数
    computedLength(row) {
      let len = 0
      if (row.children && row.children.length > 0) {
        let l = 0
        row.children.map(item => {
          l += this.computedLength(item)
        })
        len = row.children.filter(c => c.leaf)
        len = len.length + l
      }
      return len
    },
    // 计算材料未上传条数
    computedNoLength(row) {
      let len = 0
      if (row.children && row.children.length > 0) {
        let l = 0
        row.children.map(item => {
          l += this.computedNoLength(item)
        })
        len = row.children.filter(c => c.leaf && (c.result !== '0' &&c.result !== '1' && c.result !== '2' && c.result !== '3'))
        len = len.length + l
      }
      return len
    },

    getTime:function (){
      let time = new Date()
      let date = new Date(time),
          Y = date.getFullYear(), // 年
          M = date.getMonth() + 1, //月
          D = date.getDate(), //日
          h = date.getHours(), //小时
          m = date.getMinutes(), //分钟
          s = date.getSeconds(); //秒数
      this.currTime= Y + '-' + this.getZero(M) + '-' + this.getZero(D) + ' ' + this.getZero(h) + ':' +
          this.getZero(m) + ':' + this.getZero(s);
      if(M>6){
        this.currStarMonth=Y + '-' + this.getZero(M-6);
      }else{
        this.currStarMonth=(Y-1) + '-' + this.getZero(12+M-6);
      }

      this.currEndMonth=Y + '-' + this.getZero(M);


      this.startDate=this.currStarMonth;
      this.endDate=this.currEndMonth;
    },
    getZero:function(num){
      if (parseInt(num) < 10) {
        num = '0' + num;
      }
      return num;
    },
    //获取结论
    getConclusion:function (result){
      if(result=='1'){
       return '满足'
      }else if(result=='2'){
        return '基本满足'
      }else if(result=='3'){
        return '需完善'
      }
    },
    //改变状态按钮
    changeCheckbox:function (val){
      console.log(val)
    },
    //查看材料当前类型
    lookType:function (){
      const self=this;
      self.getMaterialsInfo(self.authProfileId);
    },

    //过滤出符合查询状态的材料
    filterStatus(children) {
      children.map(item => {
        if (item.children && item.children.length > 0) {
          item.children = this.filterStatus(item.children)
        }
        // if(item.result==='-1' && (null !== item.certCode && undefined !== item.certCode &&  item.certCode !== '')){
        //   item.result ='0'
        // }
        return item
      })

      // && (null !== item.certCode && undefined !== item.certCode &&  item.certCode !== '')
      return children.filter(item => (item.leaf && (this.status.indexOf(item.result) > -1)|| (!item.leaf && item.children && item.children.length>0)))
    },
    //时间改变
    changeDate:function (e){
      this.$set(this.file, 'execute', e)
    },

    // 获取机构信息
    async reqOrganDetail() {
      const self=this;
      const res = await getOrganDetail(self.organId)
      self.organ = res.biz;
      self.authProfileId=self.organ.authProfile ? self.organ.authProfile.oid:''
      self.level=self.organ.level;
      self.getMaterialsInfo(self.authProfileId);
      self.getCertResult(self.authProfileId)
      self.statistics();

    },
    //获取当前认证结果
    getCertResult:function (id){
      const self=this;
      this.$axios.get('/api/auth/'+id)
          .then((res) => {
            if(res.msg.flag == 0){
              if(null == res.biz || res.biz.empty){
                self.certResult={};
                self.$message.info({content: res.msg.message, duration: 2});
              }else{
                self.certResult=res.biz;
              }
            }else{
              self.$message.error({content: res.msg.message, duration: 2});
            }
          }).catch(() => {
      });
    },
    //获取材料模板
    getMaterialsInfo:function (oid){
      const self=this;
      let params={mode:'MATERIAL',level:self.level}
      this.$axios.get('/api/auth/'+oid+'/material',{params:params})
          .then((res) => {
            if(res.msg.flag == 0){
              if(null == res.biz || res.biz.empty){
                self.materialList=[];

                self.$message.info({content: res.msg.message, duration: 2});
              }else{
                self.materialList= (res.biz || [])
                self.materialList=self.filterStatus(res.biz)
              }
            }else{
              self.$message.error({content: res.msg.message, duration: 2});
            }
          }).catch(() => {
      });
    },
    //获取材料信息
    getMaterials:function (row){
      const self=this;
      let params={oid:row.id}
      this.$axios.get('/api/customer/auth/'+self.authProfileId+'/resource', {params:params})
          .then((res) => {
            if(res.msg.flag == 0){
              if(null == res.biz || res.biz.empty){
                self.materials=[];
                self.$message.info({content: res.msg.message, duration: 2});
              }else{
                self.materials=[];
                res.biz.forEach(function (i){
                  self.materials.push({files:i.files,name:i.name,oid:i.oid,fileName:''})
                })
                self.materialsDate=[];
                console.log(self.materials,111)
                self.materials.forEach(function (item){
                  if(null != item.files && undefined != item.files && item.files != ''){
                    item.files.forEach(function (e){
                      self.materialsDate.push({oid:item.oid,title:item.name,fileId:e.oid,execute:e.execute,file:e.file,name:e.name})
                    })
                  }

                })

              }
            }else{
              self.$message.error({content: res.msg.message, duration: 2});
            }
          }).catch(() => {
      });
    },
    //获取意见材料
    getResource:function (row){
      const self=this;
      let params={id:self.authProfileId,oid:row.id}
      this.$axios.get('/api/customer/auth/audit/resource', {params:params})
          .then((res) => {
            if(res.msg.flag == 0){
              if(null == res.biz || res.biz.empty){
                self.first={};
                self.expert={};
              }else{
                  self.first=res.biz.first || {};
                  self.expert=res.biz.expert || {};
              }
            }else{

            }
          }).catch(() => {
      });
    },
    //统计建设材料文件
    async statistics() {
      const self=this;
      console.log(self.authProfileId,11111)
      let params={id:self.authProfileId,level:self.level};
      this.$axios.get('/api/customer/auth/stats/resource/file',{params:params})
          .then((res) => {
            if(res.msg.flag == 0){
              if(null == res.biz || res.biz.empty){

                self.$message.info({content: res.msg.message, duration: 2});
              }else{
                self.total=res.biz.total;
                self.awaitnum=res.biz.await;
              }
            }else{
              self.$message.error({content: res.msg.message, duration: 2});
            }
          }).catch(() => {
      });

    },
    handleUpload(row) {
      const self=this;
      self.leafOid=row.id;
      self.leafTitle=row.title;
      self.certKey=row.certCode;
      console.log(self.certKey,888)
      self.getMaterials(row);
      self.getResource(row);
      self.row=row;
      self.dialogVisible = true
      self.fileList=[];
      self.first={};
      self.expert={};
    },
    openUpload:function (type){
      const self=this;
      self.currCode=type;
      self.dialogVisible = true
    },
    handleClose() {
      this.file = {}
      this.dialogVisible = false;
      this.getMaterialsInfo(this.authProfileId);

      // this.getMaterialsInfo(this.organ.authProfile.oid);
    },
    handleExceedDialog(res,file, filelist,oid,title,execute,index) {
      const self=this;
      if(res && res.msg.flag === 0) {
        self.itemId=oid;
        self.file={};
        self.$set(this.file, 'name', res.biz.name)
        self.$set(this.file, 'title', title)
        self.$set(this.file, 'path', res.biz.path)
        self.$set(this.file, 'execute', execute)
        self.materials[index].fileName='';
        self.materials[index].fileName=res.biz.name;
      }
    },
    beforeAvatarUpload(rawFile) {
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'application/pdf') {
        this.$message.error('请上传jpg或者png或者pdf格式的图片')
        return false
      } else if (rawFile.size / 1024 / 1024 > 5) {
        this.$message.error('文件大小不超过5MB!')
        return false
      }
      return true
    },
    //材料文件的上传
    async submitUploadDialog(index,execute) {
      const self=this;
      if (!this.file.path) {
        this.$message.error('请选择文件！')
        return
      }
      if (!execute) {
        this.$message.error('请选择执行时间！')
        return
      }
      let params={};
      params = {authId:self.authProfileId,oid:self.leafOid,itemId:self.itemId,itemName:self.file.title,name:self.leafTitle,file:self.file};
      this.$axios.post('/api/customer/auth/resource/item/upload', params)
          .then((res) => {
            if(res.msg.flag == 0){
              self.$message.success({message: res.msg.message});
              self.file.path = ''
              self.file.name = ''
              self.file.title=''
              self.row.result=res.biz;
              self.getMaterials(self.row);
              self.statistics();
              self.fileList=[];
            }else{
              self.$message.error({message: res.msg.message});
            }
          }).catch(() => {
      });


    },
    //删除文件
    handleDel:function (itemOid,fileId){
      const self=this;
      let params={oid:self.leafOid,itemId:itemOid,fileId:fileId}
      self.$axios.delete('/api/customer/auth/'+self.authProfileId+'/item/file/remove', {params:params})
          .then((res) => {
            if(null != res && null != res.msg && res.msg.flag === 0){
              if(null==res.biz || undefined == res.biz || res.biz == ''){

              }else {
                self.row.result=res.biz
              }
              self.getMaterials(self.row);
              self.statistics();
              self.$message.success({message: res.msg.message});
            }else{
              self.$message.error({message: res.msg.message});
            }
          }).catch(() => {

      });
    },
    //下载查看
    checkMaterial:function (path){
      window.open(path)
    },
    //认证申请
    async reqSubmit() {
      const self = this;
      this.error ={loading: false, message: ''};
      this.$refs.ruleFormRef.validate((valid) =>{
        if (valid) {
          this.loading = true;
          this.$axios.post('/api/customer/auth/'+self.authProfileId+'/apply',self.level)
              .then((res) => {
                self.loading = false;
                if(null != res && null != res.msg && res.msg.flag === 0){
                  self.reqOrganDetail();
                  self.$message.success({message: res.msg.message});
                }else{
                  self.$message.error({message: res.msg.message});
                }
              }).catch(() => {
            self.loading = false;

          });
        }
      });
    },
    //发起认证申请
    submitForm() {
      this.$confirm('确定发起认证申请吗？提交申请后材料将不能进行更改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.reqSubmit()
      }).catch(() => {
      });
    },
    openCert:function (){
      const self=this;
      let startTime=moment(self.startDate).format("YYYY-MM");
      let endTime=moment(self.endDate).format("YYYY-MM");
      let certKey=self.certKey;

      if(self.level == 'THREE'){

        if(certKey=='1' || certKey=='1.1' || certKey=='2' || certKey=='4' || certKey=='7' || certKey=='8' || certKey=='18' || certKey=='19' || certKey=='20.1' || certKey=='20.2' || certKey=='21' || certKey=='24'){
          let code=(self.data || []).filter(e =>e.key== certKey)[0].code;
          window.open(self.skipPageUrl+'cert/certdetail'+"?code="+code+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime,"_blank");
        }
        else if(certKey=='3' || certKey=='5' || certKey=='9' || certKey=='10' || certKey=='11' || certKey=='12' || certKey=='13' || certKey=='14' || certKey=='15' || certKey=='16' || certKey=='17' || certKey=='22' || certKey=='23'){
          let code=(self.data || []).filter(e =>e.key== certKey)[0].code;
          window.open(self.skipPageUrl+'cert/certdetailnum'+"?code="+code+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime,"_blank");
        }
        else if(certKey=='6'){
          let code=(self.data || []).filter(e =>e.key== certKey)[0].code;
          window.open(self.skipPageUrl+'cert/certdetailtimenum'+"?code="+code+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime,"_blank");
        }
        else if(certKey=='25'){
          let code=(self.data || []).filter(e =>e.key== certKey)[0].code;
          window.open(self.skipPageUrl+'cert/certdetaillast'+"?code="+code+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime,"_blank");
        }else if(certKey=='8.1'){
          // let code=(self.data || []).filter(e =>e.key== certKey)[0].code;
          window.open(self.skipPageUrl+'cert/basiccertdetail'+"?&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime+"&archived=null"+"&first="+self.fistMeasure+"&next="+self.measure.join(','),"_blank");
        }
        else if(certKey=='18.1'){
          // let code=(self.data || []).filter(e =>e.key== certKey)[0].code;
          window.open(self.skipPageUrl+'cert/basiccertdetailnum'+"?&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime+"&archived=null"+"&first="+self.fistMeasure+"&next="+self.measure.join(','),"_blank");
        }else if(certKey=='certTHREE'){

          window.open(self.skipPageUrl+'cpis/certweb'+"?level="+self.level+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime,"_blank");
        }
        else if(certKey=='standard'){

          window.open(self.skipPageUrl+'cpis/generalizationweb'+"?level="+self.level+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime,"_blank");
        }

      }
      else if(self.level == 'TWO'){

        if(certKey=='1' || certKey=='1.1' || certKey=='2' || certKey=='4' || certKey=='8' || certKey=='9' || certKey=='12' || certKey=='17' || certKey == '20.1' || certKey == '20.3' || certKey == '20.4'|| certKey=='22' || certKey=='23' || certKey=='30'){
          let code=(self.basicData || []).filter(e =>e.key== certKey)[0].code || '';
          window.open(self.skipPageUrl+'cert/basiccertdetail'+"?code="+code+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime+"&archived=null"+"&first="+self.fistMeasure+"&next="+self.measure.join(','),"_blank");
        }
        else if(certKey=='3' || certKey=='5' || certKey=='6' || certKey=='7' || certKey=='10' || certKey=='11' || certKey=='13' || certKey=='14' || certKey=='15' || certKey=='18' || certKey=='19' ||certKey == '20.2' ||certKey == '20.5' ||  certKey == '21' || certKey=='24' || certKey=='25' || certKey=='26' || certKey=='27' || certKey=='28' || certKey =='29'){
          let code=(self.basicData || []).filter(e =>e.key== certKey)[0].code || '';
          window.open(self.skipPageUrl+'cert/basiccertdetailnum'+"?code="+code+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime+"&archived=null"+"&first="+self.fistMeasure+"&next="+self.measure.join(','),"_blank");
        }
        else if(certKey=='16'){
          let code=(self.basicData || []).filter(e =>e.key== certKey)[0].code || '';
          window.open(self.skipPageUrl+'cert/basiccertdetailtimenum'+"?code="+code+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime+"&archived=null"+"&first="+self.fistMeasure+"&next="+self.measure.join(','),"_blank");
        }
        else if(certKey=='31'){
          let code=(self.basicData || []).filter(e =>e.key== certKey)[0].code || '';
          window.open(self.skipPageUrl+'cert/basiccertdetaillast'+"?code="+code+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime+"&archived=null"+"&first="+self.fistMeasure+"&next="+self.measure.join(','),"_blank");
        }else if(certKey=='certTWO'){

          window.open(self.skipPageUrl+'cpis/certweb'+"?level="+self.level+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime,"_blank");
        }
        else if(certKey=='basic'){

          window.open(self.skipPageUrl+'cpis/generalizationweb'+"?level="+self.level+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime,"_blank");
        }
        // else if((certKey=='5.35' && currkey == '20') || (code=='5.35' && currkey == '22' )|| (code=='5.35' && currkey == '23')){
        //   window.open('https://www.hunancpc.net/cpis-admin/#/cert/basiccertdetail'+"?code="+code+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime+"&archived=null"+"&first="+self.fistMeasure+"&next="+self.measure.join(','),"_blank");
        // }
        // else if((code=='5.35' && currkey == '21' )|| (code=='5.35' && currkey == '24')){
        //   window.open('https://www.hunancpc.net/cpis-admin/#/cert/basiccertdetailnum'+"?code="+code+"&key="+certKey+"&organId="+self.organId+"&organ="+self.organ.name+"&startDate="+startTime+"&endDate="+endTime+"&currTime="+self.currTime+"&archived=null"+"&first="+self.fistMeasure+"&next="+self.measure.join(','),"_blank");
        // }
      }

    },

  }
}





</script>

<style lang="scss">
.certificate-collapse {
  width: 100%;

  .el-collapse-item__header {
    background: #508caa;
    color: #fff;
    font-size: 16px;
    padding-left: 10px;
  }

  .el-collapse-item__content {
    padding-bottom: 10px;
  }

  .certificate-collapse-li {
    background: #d5f6d8;
    border-bottom: 3px solid #fff;
    line-height: 28px;
    padding: 8px 10px;
    word-wrap: break-word;
    font-size: 14px;

    & > div {
      font-weight: bold;
    }
  }

  .certificate-collapse-btn {
    width: 120px;
    text-align: center;
  }

  .certificate-collapse-second{
    .el-collapse-item__header {
      background: #9facb2!important;
      color: #fff;
      font-size: 16px;
      padding-left: 10px;
    }

  }
}

.el-divider__text {
  background-color: #d5f6d8;
  font-size: 25px;
}

.line {
  width: 100%;
  border: 1px solid #DDDFE6
}

.text-title {
  font-family: "Microsoft YaHei";
  font-size: 14px;
  font-weight: 700;
  color: #303030;
  line-height: 20px;
  border-left: 6px solid #999;
  margin: 10px 0;
  padding-left: 10px;
}
.left-style{
  border-left:1px solid black;
  border-right:1px solid black;
  border-bottom:1px solid black;
  border-left:1px solid black;
  padding: 10px;
  height: 50px;
}
.right-style{
  border-right:1px solid black;
  border-bottom:1px solid black;
  border-left:1px solid black;
  border-left: none;
  padding: 10px;
  height: 50px;
}
.greenColor{
  color: #45e845;
}
.redColor{
  color: #9a0f0f;
}
</style>