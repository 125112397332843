
const data = [
    {
        key: '1',
        code: '5.16',
        title: '对于自行来院或拨打本地120经救护车入院的所有急性胸痛患者，缩短了从首次医疗接触到完成首份心电图时间，且要求月平均小于10分钟。',
        isDisplay:true,
        apiData:'/api/dmz/cert/ecgcerts',
        apiDataDetail:'/api/dmz/cert/ecgcert/patients',
    },
    {
        key: '1.1',
        code: '5.16',
        title: '对于自行来院或拨打本地120经救护车入院的所有急性胸痛患者，缩短了从首次医疗接触到完成首份心电图时间，且要求月平均≤20分钟。',
        isDisplay:true,
        apiData:'/api/dmz/cert/twenty/ecgcerts',
        apiDataDetail:'/api/dmz/cert/twenty/ecgcert/patients',
    },
    {
        key: '2',
        code: '5.17',
        title: '对于STEMI患者，缩短了从完成首份心电图至确诊的时间，且要求月平均小于10分钟。',
        isDisplay:true,
        apiData:'/api/dmz/cert/ecgreports',
        apiDataDetail:'/api/dmz/cert/ecgreport/patients',
    },
    {
        key: '3',
        code: '5.18',
        title: '经救护车（包括呼叫本地120入院及由非PCI医院转诊患者）入院的STEMI患者，从急救现场或救护车远程传输心电图至胸痛中心（实时传输或微信等形式传输，但必须在云平台有客观记录）的比例不低于30%且在过去6个月内呈现增加趋势。',
        isDisplay:true,
        apiData:'/api/dmz/cert/ecgremotes',
        apiDataDetail:'/api/dmz/cert/ecgremote/patients',
    },
    {
        key: '4',
        code: '5.19',
        title:'建立了床旁快速检测肌钙蛋白方法，从抽血完成到获取报告时间不超过20分钟。',
        isDisplay:true,
        apiData:'/api/dmz/cert/troponin/report',
        apiDataDetail:'/api/dmz/cert/troponin/report/patients',
    },
    {
        key: '5',
        code: '5.20',
        title: '发病至首次医疗接触在12小时以内的STEMI患者实施再灌注救治的比例不低于75%。',
        isDisplay:true,
        apiData:'/api/dmz/cert/perfusion',
        apiDataDetail:'/api/dmz/cert/perfusion/patients',
    },
    {
        key: '6',
        code: '5.21',
        title: '对于接受PPCI治疗的STEMI患者，月平均入门-导丝通过时间不超过90分钟，且达标率不低于75%；若当前无法达到，则应呈现改进趋势，且应制订促进持续改进的措施，确保在通过认证后1年内逐步达到上述要求。',
        isDisplay:true,
        apiData:'/api/dmz/cert/d2w',
        apiDataDetail:'/api/dmz/cert/d2w/patients',
    },
    {
        key: '7',
        code: '5.22',
        title: '网络医院转至本院实施转运PCI的STEMI患者首次医疗接触到导丝通过时间应在120分钟以内，月平均达标率不低于50%。',
        isDisplay:true,
        apiData:'/api/dmz/cert/through',
        apiDataDetail:'/api/dmz/cert/through/patients',

    },
    {
        key: '8',
        code: '5.23',
        title: '导管室激活时间小于30分钟。',
        isDisplay:true,
        apiData:'/api/dmz/cert/active',
        apiDataDetail:'/api/dmz/cert/active/patients',
    },
    {
        key: '9',
        code: '5.24',
        title: '经救护车入院（包括呼叫本地120入院及由非PCI医院转诊患者）且接受PPCI治疗的STEMI患者，绕行急诊和CCU直达导管室的比例不低于50%，且呈现增高趋势。',
        isDisplay:true,
        apiData:'/api/dmz/cert/around',
        apiDataDetail:'/api/dmz/cert/around/patients',
    },
    {
        key: '10',
        code: '5.25',
        title: '自行来院且接受PPCI治疗的STEMI患者，绕行CCU从急诊科直接送入导管室的比例不低于75%，且呈现增高趋势。',
        isDisplay:true,
        apiData:'/api/dmz/cert/self/around',
        apiDataDetail:'/api/dmz/cert/self/around/patients',
    },
    {
        key: '11',
        code: '5.26',
        title: '溶栓后患者（包含网络医院，120及本院）24小时内早期造影的比例不低于75%。',
        isDisplay:true,
        apiData:'/api/dmz/cert/thrombus/intervene',
        apiDataDetail:'/api/dmz/cert/thrombus/intervene/patients',
    },
    {
        key: '12',
        code: '5.27',
        title: '所有STEMI患者的死亡率已降低。',
        isDisplay:false,
        apiData:'/api/dmz/cert/stemi/death',
        apiDataDetail:'/api/dmz/cert/stemi/death/patients',
    },
    {
        key: '13',
        code: '5.28',
        title: '初步诊断为NSETMI/UA的患者实施危险分层评估的比例达到100%。',
        isDisplay:false,
        apiData:'/api/dmz/cert/hazard',
        apiDataDetail:'/api/dmz/cert/hazard/patients',
    },
    {
        key: '14',
        code: '5.29',
        title: '所有危险分层评估极高危的NSTEMI/UA患者，从入门后（首次评估为极高危者）或者病情变化后（再次评估为极高危者）2小时内实施紧急PCI的比例在增加，且不低于50%。',
        isDisplay:false,
        apiData:'/api/dmz/cert/high/risk',
        apiDataDetail:'/api/dmz/cert/high/risk/patients',
    },
    {
        key: '15',
        code: '5.30',
        title: '所有危险分层评估高危NSTEMI/UA患者，从入门后（首次评估为高危者）或者病情变化后（再次评估为高危者）24小时内实施早期介入治疗的比例在增加，且不低于50%。',
        isDisplay:false,
        apiData:'/api/dmz/cert/interventional',
        apiDataDetail:'/api/dmz/cert/interventional/patients',
    },
    {
        key: '16',
        code: '5.31',
        title: '全部ACS患者院内死亡率在降低。',
        isDisplay:false,
        apiData:'/api/dmz/cert/acs/death',
        apiDataDetail:'/api/dmz/cert/acs/death/patients',
    },
    {
        key: '17',
        code: '5.32',
        title: '全部ACS患者院内心力衰竭发生率在降低。',
        isDisplay:false,
        apiData:'/api/dmz/cert/acs/heart',
        apiDataDetail:'/api/dmz/cert/acs/heart/patients',
    },
    {
        key: '18',
        code: '5.33',
        title: '所有ACS患者从确诊到负荷量双抗给药时间在10分钟以内，有缩短趋势。',
        isDisplay:false,
        apiData:'/api/dmz/cert/anti/body',
        apiDataDetail:'/api/dmz/cert/anti/body/patients',
    },
    {
        key: '19',
        code: '5.34',
        title: '所有STEMI患者（除使用第一代溶栓药者外）从确诊到静脉肝素抗凝给药时间在10分钟以内。',
        isDisplay:false,
        apiData:'/api/dmz/cert/heparin',
        apiDataDetail:'/api/dmz/cert/heparin/patients',
    },
    {
        key: '20.1',
        code: '5.35',
        title: '对于溶栓（包括本院溶栓及转诊医院溶栓）治疗者，D-to-N时间缩短',
        isDisplay:false,
        apiData:'/api/dmz/cert/dto',
        apiDataDetail:'/api/dmz/cert/dto/patients',
    },
    {
        key: '20.2',
        code: '5.35',
        title: '对于溶栓（包括本院溶栓及转诊医院溶栓）治疗者，FMC-to-N时间缩短',
        isDisplay:false,
        apiData:'/api/dmz/cert/fmc',
        apiDataDetail:'/api/dmz/cert/fmc/patients',
    },
    {
        key: '21',
        code: '5.36',
        title: '对于网络医院实施转运PCI患者，在网络医院的door-in and door-out(入门到出门）的时间在30分钟以内，若当前达不到，应有缩短趋势。',
        isDisplay:false,
        apiData:'/api/dmz/cert/trans/shipment',
        apiDataDetail:'/api/dmz/cert/trans/shipment/patients',
    },
    {
        key: '22',
        code: '5.37',
        title: 'STEMI患者中呼叫120入院的比例在增加。',
        isDisplay:false,
        apiData:'/api/dmz/cert/call120',
        apiDataDetail:'/api/dmz/cert/call120/patients',
    },
    {
        key: '23',
        code: '5.38',
        title: 'STEMI患者发病后2小时内就诊的比例在增加。',
        isDisplay:false,
        apiData:'/api/dmz/cert/onset',
        apiDataDetail:'/api/dmz/cert/onset/patients',
    },
    {
        key: '24',
        code: '5.39',
        title: '主动脉或肺动脉CTA完成时间有缩短趋势（怀疑主动脉夹层或肺动脉栓塞的患者，计算从通知CT室到CT室完成准备的时间，要求 小于30分钟）',
        isDisplay:false,
        apiData:'/api/dmz/cert/cta',
        apiDataDetail:'/api/dmz/cert/cta/patients',
    },
    {
        key: '25',
        code: '5.40',
        title: 'ACS患者出院后3个月、12个月的随访率不低于50%。',
        isDisplay:false,
        apiData:'/api/dmz/follow',
        apiDataDetail:'/api/dmz/follow/patients',
    },

];
const basicData = [
    {
        key: '1',
        code: '5.16',
        title: '对于自行来院或经救护车入院的所有急性胸痛患者，缩短了从首次医疗接触到完成首份心电图时间，且要求月平均小于10分钟。',
        mustRequirements:true,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/ecgcerts',
        apiDataDetail:'/api/dmz/cert/ecgcert/patients',
    },
    {
        key: '1.1',
        code: '5.16',
        title: '对于自行来院或拨打本地120经救护车入院的所有急性胸痛患者，缩短了从首次医疗接触到完成首份心电图时间，且要求月平均≤20分钟。',
        isDisplay:true,
        apiData:'/api/dmz/cert/twenty/ecgcerts',
        apiDataDetail:'/api/dmz/cert/twenty/ecgcert/patients',
    },
    {
        key: '2',
        code: '5.17',
        title: '对于STEMI患者，缩短了从完成首份心电图至首份心电图确诊时间，且要求月平均小于10分钟。',
        mustRequirements:true,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/ecgreports',
        apiDataDetail:'/api/dmz/cert/ecgreport/patients',
    },
    {
        key: '3',
        code: '5.18',
        title: '经救护车入院的STEMI患者，院前远程传输心电图至胸痛中心的比例不低于30%且在过去6个月内呈现增加趋势。',
        mustRequirements:true,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/ecgremotes',
        apiDataDetail:'/api/dmz/cert/ecgremote/patients',
    },
    {
        key: '4',
        code: '5.19',
        title:'肌钙蛋白从抽血完成到获取报告时间20分钟。',
        mustRequirements:true,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/troponin/report',
        apiDataDetail:'/api/dmz/cert/troponin/report/patients',
    },
    {
        key: '5',
        code: '5.20',
        title: '发病至首次医疗接触在12小时以内的STEMI患者早期再灌注治疗（溶栓+PPCI）的比例不低于75%。',
        mustRequirements:true,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/perfusion',
        apiDataDetail:'/api/dmz/cert/perfusion/patients',
    },
    {
        key: '6',
        code: '5.21',
        title: '适合溶栓的患者接受溶栓治疗的比例不低于50%且在过去6个月内呈现增加趋势。',
        mustRequirements:true,
        isDisplay:'A',
        apiData:'/api/dmz/cert/bolysis/reports',
        apiDataDetail:'get /api/dmz/cert/bolysis/patients',
    },
    {
        key: '7',
        code: '5.22',
        title: '经120入院的STEMI患者直达溶栓场所的比例大于50%或呈明显增加趋势。',
        mustRequirements:false,
        isDisplay:'A',
        apiData:'/api/dmz/cert/direct',
        apiDataDetail:'/api/dmz/cert/direct/patients',

    },
    {
        key: '8',
        code: '5.23',
        title: ' 所有院内溶栓STEMI患者进门-溶栓时间已明显缩短，平均时间应在 30 分钟以内，且至少75%的病例能达到此标准；如果目前无法达到上述要求，至少近6个月已经呈现出明显的缩短趋势且至少50% 的病例达30分钟以内，且已制定合理计划以确保在通过认证后的第1年内达到平均30分钟以内且 75% 的合格率。',
        mustRequirements:true,
        isDisplay:'A',
        apiData:'/api/dmz/cert/hospital',
        apiDataDetail:'/api/dmz/cert/hospital/patients',
    },
    {
        key: '9',
        code: '5.24',
        title: '所有院前溶栓患者，首次医疗接触-溶栓时间呈现缩短趋势，且小于30分钟的比例大于30%。',
        mustRequirements:false,
        isDisplay:'A',
        apiData:'/api/dmz/cert/pre',
        apiDataDetail:'/api/dmz/cert/pre/patients',
    },
    {
        key: '10',
        code: '5.25',
        title: '溶栓后患者早期（2小时内）转运至上级医院的比例不低于50%且呈现增加趋势。',
        mustRequirements:false,
        isDisplay:'A',
        apiData:'/api/dmz/cert/twoTransport',
        apiDataDetail:'/api/dmz/cert/twoTransport/patients',
    },
    {
        key: '11',
        code: '5.26',
        title: '溶栓（包含在网络医院，120及本院溶栓）后患者24小时内早期造影的比例不低于50%或呈明显增加趋势。',
        mustRequirements:false,
        isDisplay:'A',
        apiData:'/api/dmz/cert/thrombus/intervene',
        apiDataDetail:'/api/dmz/cert/thrombus/intervene/patients',
    },
    {
        key: '12',
        code: '5.27',
        title: '在除外合并心源性休克、急性左心衰等需要PCI医院派出救护车双程转运的患者之后，月平均入门-出门(door-in and door-out）的时间应不大于30分钟，如果目前达不到，应显示明显的缩短趋势，并且需要针对当前存在的主要问题制订改进措施，确保在通过认证后1年内逐步达到。',
        mustRequirements:true,
        isDisplay:'B',
        apiData:'/api/dmz/cert/roundTransfer',
        apiDataDetail:'/api/dmz/cert/roundTransfer/patients',
    },
    {
        key: '13',
        code: '5.28',
        title: '在过去6个月内实施转运PCI的患者中，向接收转诊的PCI医院传输心电图的比例不低于50%且呈现增长趋势。',
        mustRequirements:true,
        isDisplay:'B',
        apiData:'/api/dmz/cert/transport',
        apiDataDetail:'/api/dmz/cert/transport/patients',
    },
    {
        key: '14',
        code: '5.29',
        title: '在过去6个月内实施转运PCI的患者中绕行PCI医院急诊科和CCU直达导管室的比例不低于50%。',
        mustRequirements:true,
        isDisplay:'B',
        apiData:'/api/dmz/cert/self/around',
        apiDataDetail:'/api/dmz/cert/self/around/patients',
    },
    {
        key: '15',
        code: '5.30',
        title: '在过去6个月内实施转运PCI的STEMI患者，首次医疗接触到导丝通过时间应在120分钟以内。若单月转运病例少于5例，则应至少75%的病例能达到此标准。同时转运的上级医院实际介入手术开始时间及导丝通过时间反馈率为100%。',
        mustRequirements:true,
        isDisplay:'B',
        apiData:'/api/dmz/cert/fmcToW',
        apiDataDetail:'/api/dmz/cert/fmcToW/patients',
    },
    {
        key: '16',
        code: '5.31',
        title: '本院介入医生或转运介入医生实施PPCI月平均入门-导丝通过时间≤90分钟，且达标率≥75%，若当前无法达到，则应呈现改进趋势，且应制订措施促进改进，确保在通过认证后1年逐步达到上述要求。',
        mustRequirements:true,
        isDisplay:'C',
        apiData:'/api/dmz/cert/ppcI',
        apiDataDetail:'/api/dmz/cert/ppcI/patients',
    },
    {
        key: '17',
        code: '5.32',
        title: '急诊PCI的STEMI患者导管室激活时间 30 分钟。',
        mustRequirements:true,
        isDisplay:'C',
        apiData:'/api/dmz/cert/emergencyPci',
        apiDataDetail:'/api/dmz/cert/emergencyPci/patients',
    },
    {
        key: '18',
        code: '5.33',
        title: '经救护车入院、接受PPCI治疗的STEMI患者，若从首次医疗接触到进门时间大于30分钟，绕行急诊和CCU直达导管室的比例不低于50%，且呈现增高趋势。',
        mustRequirements:false,
        isDisplay:'C',
        apiData:'/api/dmz/cert/inpatients',
        apiDataDetail:'/api/dmz/cert/inpatients/patients',
    },
    {
        key: '19',
        code: '5.34',
        title: '自行来院、接受PPCI治疗的STEMI患者，绕行CCU从急诊科直接送入导管室的比例不低于75%，且呈现增高趋势。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/voluntarily',
        apiDataDetail:'/api/dmz/cert/voluntarily/patients',
    },
    {
        key: '20.1',
        code: '5.35',
        title: '过去6个月内月平均首次医疗接触到完成首份心电图时间小于10分钟或呈明显缩短趋势。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/fmcToEcg',
        apiDataDetail:'/api/dmz/cert/fmcToEcg/patients',
    },
    {
        key: '20.2',
        code: '5.35',
        title: '过去6个月从首诊基层医院传输到胸痛中心的心电图比例在增加。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/firstEcg',
        apiDataDetail:'/api/dmz/cert/firstEcg/patients',
    },
    {
        key: '20.3',
        code: '5.35',
        title: '过去6个月内STEMI患者从首次医疗接触到确诊的时间在缩短。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/medical',
        apiDataDetail:'/api/dmz/cert/medical/patients',
    },
    {
        key: '20.4',
        code: '5.35',
        title: '对于网络医院实施转运的STEMI患者，网络医院的door-inanddoor-out(入门到出门）的时间在30分钟以内，若当前达不到，应有缩短趋势。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/trans/shipment',
        apiDataDetail:'/api/dmz/cert/trans/shipment/patients',
    },
    {
        key: '20.5',
        code: '5.35',
        title: '从网络医院或120转诊的STEMI患者绕行本院转运至上级医院直达导管室的比例在增加。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/circumvent',
        apiDataDetail:'/api/dmz/cert/circumvent/patients',
    },
    {
        key: '21',
        code: '5.36',
        title: 'STEMI入院患者中呼叫120的比例在增加。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/call120',
        apiDataDetail:'/api/dmz/cert/call120/patients',
    },
    {
        key: '22',
        code: '5.37',
        title: '所有ACS患者从确诊到负荷量双抗给药时间在10分诊以内，有缩短趋势。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/anti/body',
        apiDataDetail:'/api/dmz/cert/anti/body/patients',
    },
    {
        key: '23',
        code: '5.38',
        title: '所有STEMI患者（除使用第一代溶栓药者外）从确诊到静脉肝素抗凝给药时间有缩短趋势。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/heparin',
        apiDataDetail:'/api/dmz/cert/heparin/patients',
    },
    {
        key: '24',
        code: '5.39',
        title: '初步诊断为NSTEMI/UA的患者实施危险分层评估的比例达到100%。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/hazard',
        apiDataDetail:'/api/dmz/cert/hazard/patients',
    },
    {
        key: '25',
        code: '5.40',
        title: '所有危险分层评估极高危的NSTEMI/UA患者，从入门后（首次评估为极高危者）或者病情变化后（再次评估为极高危者）2小时内在本院或转至上级医院实施紧急PCI的比例在增加，且不低于30%。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/high/risk',
        apiDataDetail:'/api/dmz/cert/high/risk/patients',
    },
    {
        key: '26',
        code: '5.41',
        title: '所有危险分层评估高危NSTEMI/UA患者，从入门后（首次评估为高危者）或者病情变化后（再次评估为高危者）24小时内在本院或转至上级医院实施早期介入治疗的比例在增加，且不低于30%。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/interventional',
        apiDataDetail:'/api/dmz/cert/interventional/patients',
    },
    {
        key: '27',
        code: '5.42',
        title: '全部ACS患者院内心力衰竭发生率在降低。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/acs/heart',
        apiDataDetail:'/api/dmz/cert/acs/heart/patients',
    },
    {
        key: '28',
        code: '5.43',
        title: '全部ACS患者院内死亡率在降低。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/acs/death',
        apiDataDetail:'/api/dmz/cert/acs/death/patients',
    },
    {
        key: '29',
        code: '5.44',
        title: 'STEMI患者发病后2小时内就诊的比例在增加。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/onset',
        apiDataDetail:'/api/dmz/cert/onset/patients',
    },
    {
        key: '30',
        code: '5.45',
        title: '主动脉或肺动脉CTA完成时间有缩短趋势（怀疑主动脉夹层或肺血栓栓塞的患者，计算从通知CT室到CT室完成准备的时间，要求小于30分钟）',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/cert/cta',
        apiDataDetail:'/api/dmz/cert/cta/patients',
    },
    {
        key: '31',
        code: '5.46',
        title: 'ACS患者出院后3个月、12个月的随访率不低于50%。',
        mustRequirements:false,
        isDisplay:'ABC',
        apiData:'/api/dmz/follow',
        apiDataDetail:'/api/dmz/follow/patients',
    },

];
const treatmentData = [
    {
        key: '1',
        code: '5.1',
        title: '已启用胸痛救治单元数据填报平台，且已持续填报近3月的数据。',
        apiData:'/api/dmz/thoracalgia/stats',
        apiDataDetail:'',
    },
    {
        key: '2',
        code: '5.2.1',
        title: '确保所有急性胸痛患者在首次医疗接触后能在10分钟内完成12/18导联心电图检查。',
        apiData:'',
        apiDataDetail:'',
    },
    {
        key: '3',
        code: '5.2.2',
        title: '所有急性胸痛患者在首次医疗接触后10分钟内完成首份心电图并由具备能力的医生或上级医院医生解读。',
        apiData:'',
        apiDataDetail:'',
    },
    {
        key: '4',
        code: '5.3',
        title:'若开展了床旁肌钙蛋白检测，能够在抽血后20分钟内获取检测结果。',
        apiData:'',
        apiDataDetail:'',
    },
    {
        key: '5',
        code: '5.4',
        title: '若实施本院溶栓治疗，应在患者到达后 30 分钟内开始溶栓。(根据再灌注策略选择评估)。',
        apiData:'',
        apiDataDetail:'',
    },
    {
        key: '6',
        code: '5.5',
        title: '若实施转运PCI或者转运溶栓，则应在患者到达后 30分钟内转出。(根据再灌注策略选择评估)。',
        apiData:'',
        apiDataDetail:'',
    },
    {
        key: '7',
        code: '5.6',
        title: '转运至上级胸痛中心的急性高危胸痛患者数量持续增加。',
        apiData:'',
        apiDataDetail:'',
    }
];
export default {
    data,
    basicData,
    treatmentData
}
